import './App.css';
// import Bewertungen from './Sites/Bewertungen';
import Coaching from './Sites/Coaching/coaching';
// import Cookies from './Sites/Footer/cookies';
// import Datenschutz from './Sites/Footer/datenschutz';
import Impressum from './Sites/Footer/impressum';
import AufbauKurs from './Sites/Geburtsvorbereitung/AufbauKurs';
import Geburtsvorbereitung from './Sites/Geburtsvorbereitung/Geburtsvorbereitung';
import Herzenswunsch from './Sites/Geburtsvorbereitung/Herzenswunsch';
import Warum from './Sites/Geburtsvorbereitung/Warum';
import HomePage from './Sites/HomePage';
import Kontakt from './Sites/Kontakt';
import UeberMich from './Sites/UeberMich';
import Yoga from './Sites/Yoga/Yoga';
import YogaAlle from './Sites/Yoga/YogaAlle';
// import YogaKinder from './Sites/Yoga/YogaKinder';
// import YogaBeckenboden from './Sites/Yoga/YogaBeckenboden';
import YogaMamas from './Sites/Yoga/YogaMamas';
import YogaSchwangere from './Sites/Yoga/YogaSchwangere';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className="content">
        <BrowserRouter>
          <Routes>
            <Route index element={<HomePage/>}/>
            <Route path="/home" element={<HomePage/>}/>
            <Route path="/yoga" element={<Yoga/>}/>
            <Route path="/yogaalle" element={<YogaAlle/>}/>
            {/* <Route path="/yogakinder" element={<YogaKinder/>}/> */}
            {/* <Route path="/yogabeckenboden" element={<YogaBeckenboden/>}/> */}
            <Route path="/yoganeumamas" element={<YogaMamas/>}/>
            <Route path="/yogaschwangere" element={<YogaSchwangere/>}/>
            <Route path="/geburtsvorbereitung" element={<Geburtsvorbereitung/>}/>
            <Route path="/aufbaukurs" element={<AufbauKurs/>}/>
            <Route path="/warum" element={<Warum/>}/>
            <Route path="/herzenswunsch" element={<Herzenswunsch/>}/>
            <Route path="/kontakt" element={<Kontakt/>}/>
            {/* <Route path="/bewertungen" element={<Bewertungen/>}/> */}
            <Route path="/uebermich" element={<UeberMich/>}/>
            <Route path="/coaching" element={<Coaching/>}/>
            {/* <Route path="/datenschutz" element={<Datenschutz/>}/> */}
            <Route path="/impressum" element={<Impressum/>}/>
            {/* <Route path="/cookies" element={<Cookies/>}/> */}
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
