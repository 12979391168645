// NavigationBar.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './navigationbar.css'; // Create a CSS file for styling
import Logo_transparent from './assets/Logo/Logo_transparent.png'

function NavigationBar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  let navColor = "navigation";
  if (location.pathname.includes('/yoga')) {
    navColor += " yoga";
  } else if (location.pathname.includes('/geburtsvorbereitung') || location.pathname.includes('/aufbaukurs') || location.pathname.includes('/warum') || location.pathname.includes('/herzenswunsch')){
    navColor += " geburtsvorbereitung";
  }
  else if (location.pathname.includes('/coaching')) {
    navColor += " coaching";
  }


  return (
    <nav className={navColor}>
      <a href='/'><img id="nav-logo" src={Logo_transparent} alt="Main Logo"/></a>
      <ul className={`menu ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><a href="/">Willkommen</a></li>
        <li className="dropdown">
          <a  href='/yoga'>
            <span>Yoga
            </span>
          </a>
          <ul className="dropdown-content">
            <li><a href="/yogaschwangere">für Schwangere</a></li>
            <li><a href="/yoganeumamas">für Mamas</a></li>
            {/* <li><a href="/yogabeckenboden">Beckenboden-Yoga für Mütter</a></li> */}
            <li><a href="/yogaalle">für Alle</a></li>
            {/* <li><a href="/yogakinder">für Kinder</a></li> */}
          </ul>
        </li>
        <li className="dropdown">
          <a  href='/geburtsvorbereitung'>
            <span>Geburtsvorbereitung
            </span>
          </a>
          <ul className="dropdown-content">
            <li><a href="/aufbaukurs">Aufbau des Kurses</a></li>
            <li><a href="/warum">Warum Vorbereitung?</a></li>
            <li><a href="/herzenswunsch">Mein Herzenswunsch</a></li>
          </ul>
        </li>
        <li><a href="https://herznah.tentary.com/" target='_blank' rel="noreferrer">Shop</a></li>
        {/* <li><a href="/coaching" >Shop 2</a></li> */}
        <li><a href="/uebermich">Über mich</a></li>
        <li><a href="/kontakt">Kontakt</a></li>
        {/* <li><a href="/bewertungen">Bewertungen & Presse</a></li> */}
        {/* Add more navigation items as needed */}
      </ul>
      <div className={`burger-menu ${isMobileMenuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
}

export default NavigationBar;
