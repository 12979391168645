

import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import Yoga_Logo from '../../assets/Yoga/doing-yoga-and-making-a-heart-with-hands.jpg';
// import Kursplan from '../../assets/Yoga/Kursplan.png'
import './yoga.css'
import CardWithPicturesList from '../../components/CardWithPictureList';


function Yoga() {

  const cards = [
    {
      ImageSource: 'logo1',
      HeaderLabel: "für Schwangere",
      MainLabel: "Dieser Kurs ist eine Mischung aus leichten Übungen und viel Austausch. Du kannst hier üben deinen ganzen Körper für die Herausforderungen deiner Schwangerschaft & Geburt zu stärken und Stress zu lösen (körperlich und geistig).",
      Url: '/yogaschwangere',
    },
    {
      ImageSource: "logo2",
      HeaderLabel: "für Mamas",
      MainLabel: "Stärke dich mental in deiner Rolle als Mama durch diesen Kurs. Neben leichten körperlichen Übungen, gibt es genug Zeit und Raum für unterstützenden und anregenden Austausch mit den anderen Mamas.",
      Url: '/yoganeumamas',
    },
    {
      ImageSource: "logo4",
      HeaderLabel: "für Alle",
      MainLabel: "In diesem Kurs kräftigst du ganz individuell deinen ganzen Körper für die Herausforderungen des Alltags. Ich bringe dir bei, wie du Stress lösen kannst, körperlich und geistig. Für mehr Entspannung und ein insgesamt positiveres Leben!",
      Url: '/yogaalle',
    },
    // {
    //   ImageSource: "logo3",
    //   HeaderLabel: "für Kinder",
    //   MainLabel: "Diese Kurse richten sich an Kinder (und deren Begleitung). Gemeinsam wollen wir durch spielerische Abenteuer Spaß an der körperlichen Bewegung haben, aber auch intensiv zur Ruhe kommen und unser Selbstvertrauen stärken.",
    //   Url: '/yogakinder',
    // }
    
  ];

  return (
    <>
        <NavigationBar/>
        

        <section className='yoga-picture'>
          <div className='yoga-content'>
            <div className='yoga-label-content'>
              <h2 className='yoga-label'>Öffne dein Herz,</h2>
              <h2 className='yoga-label'>erlebe Leichtigkeit.</h2>
            </div>
            <div className='yoga-img-content'>
              <div className='yoga-img-container'>
                <div className='yoga-img'>
                  <img className="yoga--main-logo" src={Yoga_Logo} alt='Yoga mit Herz'/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="yoga-whatIsHerznah">
            <h2 className="yoga-h2">Was bedeutet Herznah Yoga?</h2>
            <p><strong>Mein Yoga kommt von Herzen und geht zum Herzen!</strong></p>
            <p>Du kannst es dir vielleicht (noch) nicht vorstellen, aber für mich hat Yoga sehr viel mit dem Herzen zu tun. In meinen Kursen öffne ich ganz persönlich mein Herz für dich und lasse dich eintauchen in meine Welt des Yoga. Ich möchte dich auf geistiger und emotionaler Ebene berühren und spüren lassen, was alles in dir steckt, was für ein wunderbarer Mensch du bist und auch wie schön und erfüllend unser Leben sein kann, trotz stressigem Alltag.</p>
            <p>Ich möchte dich die Leichtigkeit und Freude spüren lassen, die Viele in unserer Gesellschaft längst vergessen haben, während sie von einem Termin zum anderen huschen und gar nicht merken, wie das Leben vorbei rast, wie viele wunderschöne kleine Momente und Möglichkeiten sie gerade verpassen...</p>
            <p><strong>Ich möchte dich (wieder) mehr mit dir selbst und dem Hier und Jetzt verbinden. Genau das kann Yoga bewirken!</strong></p>
          </div>
        </section>       

        <section className='yoga-courses'>
          <div className='yoga-courses-header'>
            <h2>Finde den passenden Kurs für dich!</h2>
            <p>Ich gestalte meine Kurse so, dass man sich die passende Schwierigkeitsstufe bei jeder Übung aussuchen kann. Also trau dich, auch als blutiger Anfänger!</p>
            <p>Im Moment gebe ich Kurse</p>
          </div>
          <div className='yoga-courses-content'>
            <CardWithPicturesList cards={cards} showButton={true}/>
          </div>
        </section> 

        {/* <section className='yoga-courseplan'>
            <h2>Kursplan</h2>
            <img src={Kursplan} className='yoga-coursplan' alt="Kursplan"/>
        </section> */}

        <Footer color="yoga"/>
    </>
  );
}

export default Yoga;
