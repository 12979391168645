import React from 'react';
import NavigationBar from '../../navigationbar';
import Footer from '../../footer';

import img1 from '../../assets/Yoga/alle/group-of-young-sporty-people-in-uttanasana-exercise.jpg';
import img2 from '../../assets/Yoga/alle/group-of-young-sporty-people-sitting-in-padmasana-pose.jpg';

function YogaAlle() {
  return (
    <>
        <NavigationBar/>
        <section className='schwanger-head'>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={img1} className='schwanger-img' alt="schwanger1"/>
            </div>
          </div>
          <div className='schwanger-img-container'>
            <div className='image-wrapper'>
              <img src={img2} className='schwanger-img' alt="schwanger2"/>
            </div>
          </div>
        </section>
        <section className='schwanger-head-label'>
          <h2>Yoga für Alle</h2>          
          <p>In diesem Kurs kräftigst du ganz individuell deinen ganzen Körper für die Herausforderungen des Alltags. Ich bringe dir bei, wie du Stress lösen kannst, körperlich und geistig. Für mehr Entspannung und ein insgesamt positiveres Leben!</p>
        </section>

        <section className='yoga-alle-details'>
            <h3>Wann & wo findet der Kurs statt?</h3>
            <br/>
            <p><strong>Dienstags 19.15 bis 20.30 Uhr (außer den 1. Dienstag im Monat)</strong>,<br/> in der alten Sparkasse (Dorfgemeinschaftshaus) <br/> Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
            <br/>
            <p><strong>Donnerstags 18:15 bis 19.30 Uhr</strong>, in der alten Sparkasse (Dorfgemeinschaftshaus) <br/> Bahnhofstraße 36 in 27389 <strong>Lauenbrück</strong></p>
            <br/>
            <hr className='solid'/>
            <br/>
            <h3>Was bietet dir der Kurs?</h3>
            <ul>
              <li>Komme in Bewegung und erfahre die positiven und entspannenden Wirkungen der Streckung und Dehnung durch Yoga</li>
              <li>Übe Stück für Stück aus deiner körperlichen Komfortzone heraus zu kommen, um deinem Körper neue Kraft zu schenken</li>
              <li>Trainiere alltagstaugliche Techniken für mehr Entspannung und ein positives Selbstwertgefühl</li>
              <li>Lerne, wie du körperliche Beschwerden durch gezielte Übungen lindern oder ihnen vorbeugen kannst</li>
            </ul>
            <br/>
            <hr className='solid'/>
            <br/>
            <h3>Was brauchst du für den Kurs?</h3>
            <br/>
            <p>Bitte bring dir folgendes zu jedem Kurstag mit:</p>
            <ul>
              <li>eine leichte (Woll-)<strong>Decke</strong></li>
              <li>ein <strong>Getränk</strong></li>
            </ul>
            <br/>
            <p>Wenn du eine eigene Matte oder Blöcke hast, kannst du diese auch gerne mitbringen.</p>
            <br/>
        </section>

        <section className='yoga-alle-kosten'>
          <div className='yoga-alle-details'>
            <h3>Kosten</h3>
            <p>7 Euro pro Termin, buchbar als 10er-Karte. <br/>Du kannst 10x innerhalb von 12 aufeinander folgenden Terminen den Kurs besuchen.</p>
                {/* <p className='schwanger-center-p'><strong>Der nächste Kurs läuft vom 11.01.-14.03.2024 (9 Termine) und ist 
                &nbsp;<a href="https://www.vhs-row.de/programm/kurs/Yoga-fuer-alle/BD71025" target='_blank' rel="noopener noreferrer">hier</a> buchbar!</strong>
                </p> */}
            <p >Der Kurs ist fortlaufend. Du kannst jederzeit einsteigen oder dich auf die Warteliste setzen lassen.
                <br/>
                Ihr könnt euch für die Kurse bei<a href='/kontakt'><strong>mir anmelden.</strong></a></p>
          </div>
          
        </section>
        <Footer color="yoga"/>
    </>
  );
}

export default YogaAlle;
